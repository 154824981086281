import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Layout, Menu, Button, Dropdown, Drawer, Radio, Typography, Avatar } from 'antd';
import type { MenuProps, MenuTheme } from 'antd';
import {
    AreaChartOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    DownOutlined,
    FileTextOutlined,
    LogoutOutlined,
    CloudUploadOutlined,
    AlignCenterOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { FaSitemap, FaCog, FaUnlockAlt, FaTv } from 'react-icons/fa';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../contexts/globalContext';
// import { GlobalPrimaryContext } from '../../context/GlobalPrimaryContext';

// @ts-ignore
import ImagemLogoHorizontalCorSemIcone from './../../assets/imagens/DESENVOLVE_SP_APENAS_ESCRITA.png';

// @ts-ignore
import ImagemLogoHorizontalBrancoSemInoce from './../../assets/imagens/DESENVOLVE_SP_APENAS_ESCRITA_WHITE.png';

// @ts-ignore
import ImagemLogoHorizontal from './../../assets/imagens/logoHorizontalBranco.png';

// @ts-ignore
import ImagemIcone from './../../assets/imagens/icone.png';
// @ts-ignore
import ImagemGovfacil from './../../assets/imagens/govfacil_logo.png';
import { SairDoSistema } from './../../services/LStorage/SairSistema';
import { getIdUsuario, getUsuario } from '../../services/LStorage/UsuarioERefresh';
import { validacaoPermissao } from '../../services/VerificaSePodeAcessar';


const { Header, Sider, Content, Footer } = Layout;
const { Text } = Typography;
// const { SubMenu } = Menu;
const { Item } = Menu;

// const IconHand = () => <Icon style={{ fontSize: 17 }} component={FaSitemap} ></Icon>
const IconCogs = (props: any) => (<Icon {...props} component={FaCog} ></Icon>);
const IconTv = (props: any) => (<Icon {...props} component={FaTv} ></Icon>);
// const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    permissao: boolean,
    classNamePopUpDrawer: string,
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    let popupClassName = classNamePopUpDrawer;
    return {
        key,
        popupClassName,
        icon,
        children,
        label,
        type,
        permissao: permissao.toString()
    } as MenuItem;
}

{/* <Menu.Item disabled key="1" icon={<UserOutlined />} >Perfil</Menu.Item>
            <Menu.Item disabled key="2" icon={<RetweetOutlined />} >Alterar Senha</Menu.Item>
            <Menu.Item disabled onClick={clickMenuDireitoConfiguracoes} icon={<IconCogs />} key="3">Configurações</Menu.Item>

            <Menu.Item onClick={clickMenuPerfilUsuario} icon={<UserOutlined />} key="2">Perfil</Menu.Item>
            <Menu.Item onClick={clickMenuDireitoUsuarios} icon={<UserOutlined />} key="3">Usuarios</Menu.Item>
            <Menu.Item onClick={onAbrirDrawerColor} key="4" icon={<IconTv />} >Tema</Menu.Item>
            <Menu.Item onClick={clickMenuDireitoSair} key="5" icon={<LogoutOutlined />} >Sair</Menu.Item> */}
// const itemsMenuConfiguracao: MenuItem[] = [
//     // getItem('Navigation One', 'sub1', <MailOutlined />, [
//     //     getItem('Option 1', '1'),
//     //     getItem('Option 2', '2'),
//     //     getItem('Option 3', '3'),
//     //     getItem('Option 4', '4'),
//     // ]),
//     getItem(true, 'Perfil', 'perfil1', <UserOutlined />),
//     getItem(validacaoPermissao(['manage_user']), 'Usuarios', 'usuarios1', <UserOutlined />),
//     getItem(true, 'Tema', 'tema1', <IconTv />),
//     getItem(true, 'Sair', 'sair1', <LogoutOutlined />),

// ];

// {/* <Menu.Item key="1" onClick={clickMenuMunicipios} icon={<AlignCenterOutlined />}>

//                     </Menu.Item>
//                     <Menu.Item key="2" onClick={clickMenuRelatorioConsolidado} icon={<FileTextOutlined />}>
//                         Relatório Consolidado
//                     </Menu.Item> */}
// const itemsMenuPainel: MenuItem[] = [
//     getItem(true, 'Municípios', '1', <AlignCenterOutlined />),
//     getItem(true, 'Listagem Municípios', '4', <MenuOutlined />),
//     getItem(true, 'Relatório Consolidado', '2', <FileTextOutlined />),
//     getItem(validacaoPermissao(['manage_user']), 'Importação de Arquivos', '3', <CloudUploadOutlined />),

// ];

export default function LayoutDashboard({ children }: any) {

    const navigate = useNavigate();
    const { theme, updateTheme } = useContext(GlobalContext);

    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState<number>(77);
    const [visibleDrawer, setvisibleDrawer] = useState(false);
    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);
    const [posicaoMenu, setPosicaoMenu] = useState('1');
    const [abrirMenuLateral, setAbrirMenuLateral] = useState<Array<string>>(['']);
    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');
    const [itemsMenuConfiguracaoState, setItemsMenuConfiguracaoState] = useState<MenuItem[]>([]);
    const [itemsMenuPainelState, setItemsMenuPainelState] = useState<MenuItem[]>([]);

    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onchangeRadioTheme = useCallback((value: any): void => {

        updateTheme(value.target.value)

        window.location.reload();
    }, []);

    useEffect(() => {
        //     // let arrayUrl = window.location.pathname.split("/");

        let dataUsuario = getUsuario();
        if (dataUsuario) {
            let arrayUrl = dataUsuario.nome.split(" ")
            setNomeUsuarioLogado(arrayUrl[0])
        }

        let isDrawerOpned = '';

        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                isDrawerOpned = 'dspclassMenuPopUpDraweer';
                setCollapsedWidth(0);
            }

            let arrayUrl = window.location.pathname.split("/");

            switch (window.location.pathname) {
                // case "/dashboard":
                //     setPosicaoMenu('1')
                //     break;
                case "/municipios":
                    setPosicaoMenu('1')
                    setAbrirMenuLateral([''])
                    break;
                case "/relatorioConsolidado":
                    setPosicaoMenu('2')
                    setAbrirMenuLateral([''])
                    break;
                case "/upload":
                    setPosicaoMenu('3')
                    setAbrirMenuLateral([''])
                    break;
                case "/listagemMunicipios":
                    setPosicaoMenu('4')
                    setAbrirMenuLateral([''])
                    break;
                case "/limites-e-taxas-list":
                    setPosicaoMenu('limites-e-taxas-list')
                    setAbrirMenuLateral([''])
                    break;
                case "/configuracoes/projecao-limites":
                    setAbrirMenuLateral(['limitesOperacao'])
                    setPosicaoMenu('projecaoDeLimites')
                    break;
                case "/configuracoes/indexador":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('indexador')
                    break;
                case "/configuracoes/indexador/" + arrayUrl[3]:
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('indexador')
                    break;
                case "/configuracoes/produto":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('produto')
                    break;
                case "/configuracoes/produto/" + arrayUrl[3]:
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('produto')
                    break;
                case "/configuracoes/posicao-operacao":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('posicaoOperacao')
                    break;
                case "/configuracoes/posicao-operacao/" + arrayUrl[3]:
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('posicaoOperacao')
                    break;
                case "/configuracoes/status":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('Status1')
                    break;
                case "/configuracoes/status/" + arrayUrl[3]:
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('Status1')
                    break;
                case "/configuracoes/objeto":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('objeto')
                    break;
                case "/configuracoes/objeto/" + arrayUrl[3]:
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('objeto')
                    break;
                case "/configuracoes/limite":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('limiteOperacao')
                    break;
                case "/configuracoes/limite/" + arrayUrl[3]:
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('limiteOperacao')
                    break;
                case "/configuracoes/arquivos-importacao":
                    setAbrirMenuLateral(['limitesOperacao', 'configuracoes'])
                    setPosicaoMenu('arquivosDeImportacao')
                    break;
                case "/limites-operacao/importacao-planilha":
                    setAbrirMenuLateral(['limitesOperacao'])
                    setPosicaoMenu('importacaoDePlanilha')
                    break;

                default:
                    setPosicaoMenu('')
                    setAbrirMenuLateral([''])
                    break;
            }
        }

        const itemsMenuConfiguracao: MenuItem[] = [
            getItem(true, isDrawerOpned, 'Perfil', 'perfil1', <UserOutlined />),
            getItem(validacaoPermissao(['manage_user']), isDrawerOpned, 'Usuarios', 'usuarios1', <UserOutlined />),
            getItem(true, isDrawerOpned, 'Tema', 'tema1', <IconTv />),
            getItem(true, isDrawerOpned, 'Sair', 'sair1', <LogoutOutlined />),
        ];

        const itemsMenuPainel: MenuItem[] = [
            getItem(true, isDrawerOpned, 'Municípios', '1', <AlignCenterOutlined />),
            getItem(true, isDrawerOpned, 'Listagem Municípios', '4', <MenuOutlined />),
            getItem(validacaoPermissao(['view_consolidate_report']), isDrawerOpned, 'Relatório Consolidado', '2', <FileTextOutlined />),
            getItem(validacaoPermissao(['import_files']), isDrawerOpned, 'Importação de Arquivos', '3', <CloudUploadOutlined />),
            getItem(validacaoPermissao(['view_limites_e_taxas']), isDrawerOpned, 'Limites e Taxas', 'limites-e-taxas-list', <MenuOutlined />),
            getItem(validacaoPermissao(['manage_limit_operation']), isDrawerOpned, 'Limites de operação', 'limitesOperacao', <AreaChartOutlined />,
                [
                    getItem(true, isDrawerOpned, 'Importação de planilha', 'importacaoDePlanilha', <CloudUploadOutlined />),
                    // getItem(true, isDrawerOpned, 'Operações', 'operacoes', <AreaChartOutlined />),
                    getItem(true, isDrawerOpned, 'Projeção de Limites', 'projecaoDeLimites', <AreaChartOutlined />),
                    getItem(true, isDrawerOpned, 'Configurações', 'configuracoes', <IconCogs />,
                        [
                            getItem(true, isDrawerOpned, 'Indexadores', 'indexador', <IconCogs />),
                            getItem(true, isDrawerOpned, 'Produtos', 'produto', <IconCogs />),
                            getItem(true, isDrawerOpned, 'Posição', 'posicaoOperacao', <IconCogs />),
                            getItem(true, isDrawerOpned, 'Status', 'Status1', <IconCogs />),
                            getItem(true, isDrawerOpned, 'Objetos', 'objeto', <IconCogs />),
                            getItem(true, isDrawerOpned, 'Limites de operação', 'limiteOperacao', <IconCogs />),
                            getItem(true, isDrawerOpned, 'Arquivos de importação', 'arquivosDeImportacao', <IconCogs />),
                        ]
                    ),
                ]
            ),
        ];

        const menuConfig = itemsMenuConfiguracao.filter((element: any) => element?.permissao === 'true')
        setItemsMenuConfiguracaoState(menuConfig)
        const menuPainel = itemsMenuPainel.filter((element: any) => element?.permissao === 'true')
        setItemsMenuPainelState(menuPainel)

    }, []);

    // const clickMenuUsuarios = useCallback((): void => {
    //     let arrayUrl = window.location.pathname.split("/");

    //     if (arrayUrl[1] === 'usuarios' && !arrayUrl[2]) {

    //     } else {
    //         // navigate("/usuarios");
    //     }
    //     setvisibleDrawer(false);
    // }, [])

    // const clickMenuMeterial = useCallback((): void => {
    //     navigate("/admin/materialui");
    //     setvisibleDrawer(false);
    // }, []);

    // const clickMenuDashboard = useCallback((): void => {
    //     navigate("/dashboard");
    //     setvisibleDrawer(false);
    // }, []);

    const toggle = useCallback((): void => {
        if (collapsedWidth === 0) {
            setvisibleDrawer(!visibleDrawer);
        } else {
            setCollapsed(!collapsed);
        }
    }, [collapsedWidth, visibleDrawer, collapsed]);

    const onCollapse = useCallback((collapsedVal: boolean): void => {
        if (!collapsed) {
            setCollapsed(collapsedVal);
            setCollapsedWidth(77);
        } else {
            setCollapsed(collapsedVal);
            setCollapsedWidth(0);
        }
    }, [collapsed, collapsedWidth]);

    const onCloseDrawer = useCallback((): void => {
        setvisibleDrawer(false);
    }, []);

    const clickMenuDireitoConfiguracoes = useCallback((): void => {
        navigate("/configuracoesdosistema");
    }, []);

    const clickMenuDireitoUsuarios = useCallback((): void => {
        navigate("/usuarios");
    }, []);

    const clickMenuPerfilUsuario = useCallback((): void => {
        let id = getIdUsuario();
        navigate("/perfil/" + id);
    }, []);

    const clickMenuDireitoSair = useCallback((): void => {
        SairDoSistema();
        navigate("/");
        // localStorage.removeItem('@GovFacil:token');
        // dispatch(LoginActions.loginAlterSingOut());
    }, []);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case 'perfil1':
                clickMenuPerfilUsuario();
                break;
            case 'usuarios1':
                clickMenuDireitoUsuarios();
                break;
            case 'tema1':
                onAbrirDrawerColor();
                break;
            case 'sair1':
                clickMenuDireitoSair();
                break;

            default:
                break;
        }

    }, []);

    const menuPerfil = useCallback(() => (
        <Menu items={itemsMenuConfiguracaoState} onClick={onClickMenuConfiguracoes} />
    ), [itemsMenuConfiguracaoState]);


    const clickNavigateMenuLateral = useCallback((rota: string): void => {
        navigate(rota);
        setvisibleDrawer(false);
    }, []);

    const clickMenuPrinciopal = useCallback(({ key }: any): void => {

        setPosicaoMenu(key)

        switch (key) {
            case '1':
                clickNavigateMenuLateral("/municipios");
                break;
            case '2':
                clickNavigateMenuLateral('/relatorioConsolidado');
                break;
            case '3':
                clickNavigateMenuLateral('/upload');
                break;
            case '4':
                clickNavigateMenuLateral('/listagemMunicipios');
                break;
            case 'projecaoDeLimites':
                clickNavigateMenuLateral('/configuracoes/projecao-limites');
                break;
            case 'indexador':
                clickNavigateMenuLateral('/configuracoes/indexador');
                break;
            case 'produto':
                clickNavigateMenuLateral('/configuracoes/produto');
                break;
            case 'posicaoOperacao':
                clickNavigateMenuLateral('/configuracoes/posicao-operacao');
                break;
            case 'Status1':
                clickNavigateMenuLateral('/configuracoes/status');
                break;
            case 'objeto':
                clickNavigateMenuLateral('/configuracoes/objeto');
                break;
            case 'limiteOperacao':
                clickNavigateMenuLateral('/configuracoes/limite');
                break;
            case 'arquivosDeImportacao':
                clickNavigateMenuLateral('/configuracoes/arquivos-importacao');
                break;
            case 'importacaoDePlanilha':
                clickNavigateMenuLateral('/limites-operacao/importacao-planilha');
                break;
            case 'limites-e-taxas-list':
                clickNavigateMenuLateral('/limites-e-taxas-list');
                break;

            default:
                break;
        }
    }, []);


    return (
        <>
            <Drawer
                placement="left"
                closable={false}
                onClose={onCloseDrawer}
                visible={visibleDrawer}
                key="left"
                bodyStyle={{ backgroundColor: theme === 'light' ? '#001529' : '#141414' }}
            // bodyStyle={{background:'red'}}
            >
                <div>
                    <img
                        style={{
                            width: 220,
                            marginTop: -20,
                            marginLeft: -20,
                            marginBottom: 5
                        }}
                        src={ImagemLogoHorizontal}
                    />
                </div>
                <Menu
                    mode="inline"
                    onClick={position => {
                        clickMenuPrinciopal(position)
                    }}
                    defaultSelectedKeys={[posicaoMenu]}
                    selectedKeys={[posicaoMenu]}
                    // openKeys={abrirMenuLateral}
                    forceSubMenuRender={false}
                    triggerSubMenuAction="click"
                    onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                    className="DraerwMenuPrinmcipal"
                    theme="dark"
                    style={{
                        marginLeft: -24,
                        marginRight: -24,
                        // padding: 0
                    }}
                    items={itemsMenuPainelState}
                />
            </Drawer>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    width={280}
                    collapsedWidth={collapsedWidth}
                    breakpoint="md"
                    onBreakpoint={broken => {
                        onCollapse(broken);
                    }}
                    collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                >
                    {collapsed ?
                        <img style={{
                            width: 50,
                            margin: 10,
                            marginLeft: 12
                        }} src={ImagemIcone} />
                        : <img style={{
                            margin: 15,
                            width: 220
                        }} src={ImagemLogoHorizontal} />}
                    <Menu
                        theme="dark"
                        mode="inline"
                        onClick={position => { clickMenuPrinciopal(position) }}
                        defaultSelectedKeys={['1']}
                        selectedKeys={[posicaoMenu]}
                        onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                        openKeys={abrirMenuLateral}
                        items={itemsMenuPainelState}
                    />
                </Sider>

                <Layout className={collapsed ? 'siteLayout2' : 'siteLayout'}>
                    <Header
                        style={{
                            position: 'fixed',
                            zIndex: 99,
                            padding: 0,
                            width: collapsedWidth === 0 ? '100%' : collapsed ? 'calc(100% - 77px)' : 'calc(100% - 280px)',
                            // backgroundColor: theme === 'light' ? '#fff' : '#141414'
                            backgroundColor: theme === 'light' ? '#fff' : '#141414'
                        }}
                    >
                        <div style={{ float: "left" }}>
                            {React.createElement(
                                collapsedWidth === 0 ?
                                    MenuOutlined
                                    :
                                    collapsed ?
                                        MenuUnfoldOutlined : MenuFoldOutlined
                                ,
                                {
                                    className: 'trigger',
                                    onClick: toggle
                                }
                            )}
                        </div>


                        {
                            collapsedWidth === 0 ?
                                theme === 'light' ?
                                    <img style={{
                                        // margin: 15,
                                        width: 165,
                                        // height: 42
                                    }} src={ImagemLogoHorizontalCorSemIcone} />
                                    :
                                    <img style={{
                                        // margin: 15,
                                        width: 165
                                    }} src={ImagemLogoHorizontalBrancoSemInoce} />
                                :
                                <></>

                        }


                        {/* <Text
                            style={{
                                fontWeight: 'bold',
                                fontSize: 16
                            }}
                        >Sistema em desenvolvimento!</Text> */}
                        <Dropdown.Button
                            // onClick={handleButtonClick}
                            trigger={['click']}
                            className="dropDownMenu"
                            overlay={menuPerfil}
                            buttonsRender={([leftButton, rightButton]) => ([
                                <></>,
                                <Button
                                    style={{
                                        // marginRight: 10,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                    type="text">
                                    {
                                        collapsedWidth !== 0 &&
                                        <Text
                                            style={{
                                                marginRight: '6px'
                                            }}
                                        >{nomeUsuarioLogado}</Text>
                                    }
                                    <Avatar size={28} icon={<UserOutlined />} />
                                </Button>

                            ])}

                        />
                        {/* <Dropdown className="dropDownMenu" trigger={['click']} overlay={menuPerfil} >
                            <Button type="text">
                                Opções <DownOutlined />
                            </Button>
                        </Dropdown> */}
                    </Header>
                    <Content
                        className={'siteLayoutBackground'}
                        style={{
                            backgroundColor: theme === 'light' ? '#fff' : '#141414'
                        }}
                    >
                        {children}
                    </Content>
                    <Footer
                        style={{ textAlign: 'center', marginTop: -10, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}
                    >
                        Desenvolvido por <img style={{
                            width: 90,
                            marginLeft: 5
                        }} src={ImagemGovfacil} />
                    </Footer>
                    {/* <Footer
                        style={{
                            paddingLeft: 0, paddingRight: 0, marginTop: -10,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: collapsedWidth === 0 ? 'column' : 'row',
                        }}
                    >
                        <div
                            style={{
                                width: collapsedWidth === 0 ? '100%' : '50%',
                                display: 'flex',
                                justifyContent: collapsedWidth === 0 ? 'center' : 'flex-end',
                            }}
                        >
                            Desenvolvido por <img style={{
                                width: 90,
                                marginLeft: 5,
                                height: 21
                            }} src={ImagemGovfacil} />
                        </div>
                        <div
                            style={{
                                width: collapsedWidth === 0 ? '100%' : '50%',
                                display: 'flex',
                                justifyContent: collapsedWidth === 0 ? 'center' : 'flex-end',
                                marginRight: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    marginLeft: 10,
                                    color: 'red'
                                }}
                            >Sistema em desenvolvimento!</Text>
                        </div>
                    </Footer> */}

                </Layout>
            </Layout>
            {/* Drawer para alterar cor do sistema */}
            <Drawer
                title="Tema do sistema"
                placement="right"
                closable={false}
                onClose={onCloseDrawerColor}
                visible={visibleDrawerColor}
            >
                <Radio.Group
                    onChange={onchangeRadioTheme}
                    defaultValue={theme}
                    size="large">
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                    {/* <Radio.Button value="c">Beijing</Radio.Button> */}
                </Radio.Group>
            </Drawer>
        </>
    );
};
