import { useCallback, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { Button, notification, Row, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

import { validacaoPermissao, VerificaRota } from './../../services/VerificaSePodeAcessar';
import { urlLimitesETaxas, urlMunicipioArquivoUpload } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';

import { convertDataUSParaBrasilEHora, convertMoedaBRDoJSComExtamente2CasasDecimais, poeValorComPontosEmMil } from './../../services/Conversores';

import { useGetColumnSearchProps } from './../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from './../../hooks/FilterTable/date';
import { useGetColumnSearchPropsObj } from '../../hooks/FilterTable/objString';
import { mask, unMask } from '../../utils/MascaraDeCampos';
import { SearchOutlined } from '@ant-design/icons';
interface IData {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "cnpj": string,
    "nome": string,
    "cpf": string,
    "email": string,
    "telefoneFixo": string,
    "celular": string,
    "municipioIdIbge": number,
    "municipio": {
        "nome": string,
        "taxaDeJuros": number | null,
        "limitePreAprovado": number | null
    }
}

export const useListagemPreCadastro = () => {

    const navigate = useNavigate();

    const refContagemDoFiltro = useRef<Array<any>>([]);

    const refDadosTabelaState = useRef<Array<IData>>([]);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IData>>([]);

    const [loadingPage, setLoadingPage] = useState(false);
    const [modalViewDados, setModalViewDados] = useState(false);
    const [dadosViewDadosInModal, setDadosViewDadosInModal] = useState<IData>();

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                setLoadingPage(true);
                let result = await api.get(urlLimitesETaxas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDadosTabelaState.current = result.data
                setDadosTabelaState(result.data);

                setLoadingPage(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingPage(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate, true, validacaoPermissao(['view_limites_e_taxas']));

        buscaCidades();


    }, []);


    const maskCNPJ = useCallback((value: any) => {
        if (!value) return '';

        const originalValue = unMask(value);
        const maskedValue = mask(originalValue, [
            "99.999.999/9999-99"
        ]);

        return maskedValue

    }, []);

    const maskCPF = useCallback((value: any) => {
        if (!value) return '';

        const originalValue = unMask(value);
        const maskedValue = mask(originalValue, [
            "999.999.999-9",
            "999.999.999-99"
        ]);

        return maskedValue

    }, []);

    const maskTelefone = useCallback((value: any) => {
        if (!value) return '';

        const originalValue = unMask(value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        return maskedValue

    }, []);

    const clickBotaoViewDadosModal = useCallback((idModal: string): void => {

        let objClicado = refDadosTabelaState.current.filter(element => {
            return element.id === idModal
        });

        let objView = {
            "id": objClicado[0]?.id,
            "createdAt": moment(objClicado[0]?.createdAt).format('DD/MM/YYYY HH:mm'),
            "updatedAt": moment(objClicado[0]?.updatedAt).format('DD/MM/YYYY HH:mm'),
            "cnpj": maskCNPJ(objClicado[0]?.cnpj),
            "nome": objClicado[0]?.nome,
            "cpf": maskCPF(objClicado[0]?.cpf),
            "email": objClicado[0]?.email,
            "telefoneFixo": maskTelefone(objClicado[0]?.telefoneFixo),
            "celular": maskTelefone(objClicado[0]?.celular),
            "municipioIdIbge": objClicado[0]?.municipioIdIbge,
            "municipio": {
                "nome": objClicado[0]?.municipio?.nome,
                "taxaDeJuros": objClicado[0]?.municipio?.taxaDeJuros,
                "limitePreAprovado": objClicado[0]?.municipio?.limitePreAprovado,
            }
        }

        setModalViewDados(true);

        setDadosViewDadosInModal(objView)

    }, []);


    const columns = [
        {
            title: 'Município',
            dataIndex: 'municipio',
            ...useGetColumnSearchPropsObj('municipio'),
            width: '10%',
            sorter: (a: any, b: any) => a.municipio?.nome.localeCompare(b.municipio?.nome),
            showSorterTooltip: false,
            render: (municipio: any) => {

                return municipio?.nome
            },
        },
        {
            title: 'CNPJ',
            dataIndex: 'cnpj',
            ...useGetColumnSearchProps('cnpj'),
            width: '10%',
            sorter: (a: any, b: any) => a?.cnpj?.localeCompare(b?.cnpj),
            showSorterTooltip: false,
            render: (cnpj: any) => {

                return maskCNPJ(cnpj)
            },
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a?.nome?.localeCompare(b?.nome),
            showSorterTooltip: false,
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            ...useGetColumnSearchProps('cpf'),
            width: '10%',
            sorter: (a: any, b: any) => a?.cpf?.localeCompare(b?.cpf),
            showSorterTooltip: false,
            render: (cpf: any) => {

                return maskCPF(cpf)
            },
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            ...useGetColumnSearchProps('email'),
            width: '10%',
            sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
            showSorterTooltip: false,
        },
        {
            title: 'Telefone Fixo',
            dataIndex: 'telefoneFixo',
            ...useGetColumnSearchProps('telefoneFixo'),
            width: '10%',
            sorter: (a: any, b: any) => a?.telefoneFixo?.localeCompare(b?.telefoneFixo),
            showSorterTooltip: false,
            render: (numero: any) => {

                return maskTelefone(numero)
            },
        },
        {
            title: 'Celular',
            dataIndex: 'celular',
            ...useGetColumnSearchProps('celular'),
            width: '10%',
            sorter: (a: any, b: any) => a?.celular?.localeCompare(b?.celular),
            showSorterTooltip: false,
            render: (numero: any) => {

                return maskTelefone(numero)
            },
        },
        {
            title: 'Limite pré-aprovado',
            dataIndex: 'municipio',
            // ...useGetColumnSearchProps('limitePreAprovado'),
            width: '10%',
            // sorter: (a: any, b: any) => a?.municipio?.limitePreAprovado - b?.municipio?.limitePreAprovado,
            // sorter: (a: any, b: any) => a?.municipio?.limitePreAprovado?.localeCompare(b?.municipio?.limitePreAprovado),
            showSorterTooltip: false,
            render: (municipio: any) => {

                return 'R$ ' + convertMoedaBRDoJSComExtamente2CasasDecimais(municipio?.limitePreAprovado, true)
            },
        },
        {
            title: 'Taxa de juros',
            dataIndex: 'municipio',
            // ...useGetColumnSearchProps('taxaDeJuros'),
            width: '10%',
            // sorter: (a: any, b: any) => a?.municipio?.taxaDeJuros - b?.municipio?.taxaDeJuros,
            // sorter: (a: any, b: any) => a?.municipio?.taxaDeJuros?.localeCompare(b?.municipio?.taxaDeJuros),
            showSorterTooltip: false,
            render: (municipio: any) => {

                return convertMoedaBRDoJSComExtamente2CasasDecimais(municipio?.taxaDeJuros, true)
            },
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.update_at.localeCompare(b.update_at),
            render: (data: any) => {

                return (
                    convertDataUSParaBrasilEHora(data)
                )
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'id',
            // ...getColumnSearchProps('created_at'),
            width: '1%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (idModal: any) => (
                <>
                    <Row>
                        <Tooltip title="Visualizar" color="blue" key="visualizarblue">
                            <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                onClick={() => clickBotaoViewDadosModal(idModal)}
                            >
                                <SearchOutlined />
                            </Button>
                        </Tooltip>
                    </Row>
                </>
            ),
        },
    ];

    return {
        loadingPage,
        columns,
        dadosTabelaState,
        refContagemDoFiltro,

        setModalViewDados,
        dadosViewDadosInModal,
        modalViewDados,
    }
}
